<template>
  <registration-code-edit-create :organization-id="$auth.me.organization_id" />
</template>

<script>
import Vue from 'vue';
import RegistrationCodeEditCreate from '~/pages/shared/registration-codes/partials/EditCreate.vue';

export default Vue.extend({

  components: {
    RegistrationCodeEditCreate,
  },

  middleware: 'employee',
});
</script>
